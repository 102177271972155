<template>
  <div class="middle-navbar">
    <v-row
      v-if="!mobile()"
      class="ma-0"
    >
      <client-only>
        <v-col cols="6">
          <div class="text-left d-flex align-center">
            <v-img
              v-if="!mobile()"
              :src="require('@/assets/images/global/go-green.png')"
              class="ml-2 mr-2"
              alt="cart-logo"
              max-width="20"
            />
            <p class="ma-0">
              Now Shipping Carbon Neutral
            </p>
          </div>
        </v-col>
        <v-col
          cols="6"
          class="text-right"
        >
          <n-link
            v-show="!mobile()"
            to="/support"
            class="pa-2 text-uppercase custom-font-size primary--text"
            color="primary-color"
          >
            Support
          </n-link>
          <a
            v-show="!mobile()"
            href="#"
            class="pa-2 text-uppercase custom-font-size"
            color="primary-color"
          >800-482-2712</a>
          <!-- Blog removed for now. May come back in the future. -->
          <!-- <n-link
            v-show="!mobile()"
            to="/blog"
            class="pa-2 middle-navbar__blog notera tertiary--text"
            style="font-size: 34px;"
          >
            Blog
          </n-link> -->
        </v-col>
      </client-only>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TheMiddleNavbar',
}
</script>

<style lang="scss" scoped>
  .middle-navbar {
    z-index: 999;
    background-color: $white;
    position: relative ;
    box-shadow: 0 1px 7px -6px #000000;
    -webkit-box-shadow: 0 1px 7px -6px #000000;
    &__blog {
      color: $tertiary-color !important;
    }
    a {
      display: inline-block;
      &:hover {
        color:  $medium-dark-gray !important;
      }
    }
  }
  .custom-font-size {
    font-size: 14px;
  }
  .text-left {
    height: 100%;
  }

</style>
