<template>
  <modal
    name="cart"
    :class="mobile() ? 'modal--mobile checkout-modal' : 'checkout-modal-desktop'"
    height="auto"
    width="100%"
    :max-width="900"
    :adaptive="true"
    scrollable
  >
    <v-row class="checkout-modal__header light-gray font-weight-bold pa-4 no-gutters">
      <v-col cols="11">
        <h4 class="mb-0">
          Shopping Cart
        </h4>
      </v-col>
      <v-col
        cols="1"
        class="text-right"
      >
        <v-icon
          :large="!mobile()"
          :medium="mobile()"
          @click="$modal.hide('cart')"
        >
          mdi-close
        </v-icon>
      </v-col>
    </v-row>
    <div :class="mobile() ? 'd-flex flex-column pa-4 checkout-modal__body--mobile' : 'd-flex flex-column pa-4 checkout-modal__body--desktop'">
      <v-row
        v-if="numberOfItems > 0"
        :class="mobile() ? 'ma-0' : 'ma-0'"
      >
        <v-col :class="mobile() ? '' : 'flex-grow-1 flex-shrink-0'">
          <h6 class="mb-0 lato-bold text--small">
            Name
          </h6>
        </v-col>
        <v-col :class="mobile() ? '' : 'flex-grow-1 flex-shrink-0'">
          <h6 class="mb-0 lato-bold text--small">
            Fabric
          </h6>
        </v-col>
        <v-col :class="mobile() ? 'flex-grow-0 flex-shrink-1 flex-basis-a w-auto' : 'flex-grow-0 flex-shrink-1 flex-basis-a w-auto line-items-header__price'">
          <h6 class="mb-0 lato-bold text--small">
            Price
          </h6>
        </v-col>
        <v-col :class="mobile() ? '' : 'flex-grow-1 flex-shrink-0 flex-basis-a w-auto line-items-header__qty'">
          <h6 class="mb-0 lato-bold text--small w-100">
            Qty
          </h6>
        </v-col>
        <v-col :class="mobile() ? 'd-none' : 'flex-grow-0 flex-shrink-1 flex-basis-a w-auto line-items-header__line-total'">
          <h6 class="mb-0 lato-bold text--small">
            Line Total
          </h6>
        </v-col>
        <v-col
          v-if="!enrolledInRiskFree"
          class="text-center flex-grow-0 flex-shrink-1 flex-basis-a w-auto"
        >
          <h6 class="mb-0 lato-bold text--small">
            <v-icon class="opacity-0">
              mdi-close
            </v-icon>
          </h6>
        </v-col>
      </v-row>
      <div
        v-if="numberOfItems > 0"
        :class="mobile () ? 'overflow--scroll line-items ma-0' : 'overflow--scroll line-items ma-0'"
      >
        <v-row class="ma-0">
          <v-col
            v-for="(item, index) in lineItems"
            :key="item.name + item.fabric"
            cols="12"
            class="pa-0"
          >
            <line-items
              :item="item"
              :index="index"
            />
          </v-col>
          <v-row
            v-if="enrolledInRiskFree && numberOfItems > 0"
            id="risk-free-message"
            class="ma-0"
          >
            <v-col class="pa-4">
              You are enrolled in the No-Risk Trial and are ready for checkout. While enrolled, you won’t be able to add anything else to the shopping cart except for fabric samples. If you add additional units prior to checkout, you will be automatically unenrolled from the program.
            </v-col>
          </v-row>
        </v-row>
      </div>
      <v-row
        v-if="enrolledInRiskFree && numberOfItems < 1"
        id="risk-free-message"
        class="cart-message"
      >
        <v-col class="pa-8">
          You are enrolled in the No-Risk Trial but haven't added your No-Risk trial to the cart. Please choose your fabric and add your No-Risk trial to the cart or un-enroll.
        </v-col>
      </v-row>
      <v-row
        v-if="!enrolledInRiskFree && numberOfItems < 1"
        class="cart-message"
      >
        <v-col>
          <h5 class="text-center mt-8">
            Your shopping cart is empty.
          </h5>
        </v-col>
      </v-row>
      <v-row class="text-right">
        <v-col>
          <h5>
            Subtotal ({{ numberOfItems }} items): {{ total | currency }}
          </h5>
          <h5 class="mb-0">
            Shipping <small>(Standard Flat Rate</small>): {{ totalShipping | currency }}
          </h5>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <hr class="ma-0">
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7">
          <div class="d-flex">
            <div class="pr-3">
              <v-img
                :height="35"
                :width="35"
                :src="require('@/assets/images/Leaf.png')"
                class="mr-0 ml-auto"
              />
            </div>
            <div class="sustainability-text">
              <span v-if="!mobile()">We are 100% water-neutral, and<br>one tree is planted for every sectional order.
              </span>
              <span v-else>We plant one tree<br>for every order.
              </span>
            </div>
          </div>
        </v-col>
        <v-col cols="5">
          <h5 class="mb-0 text-right">
            Total: {{ total + totalShipping | currency }}
          </h5>
        </v-col>
      </v-row>
      <v-row class="checkout-buttons">
        <v-col
          cols="6"
        >
          <v-btn
            id="keep-shopping-btn"
            class="grey white--text flex-grow-1 mb-0"
            tile
            depressed
            x-large
            block
            @click="$modal.hide('cart');"
          >
            KEEP SHOPPING
          </v-btn>
        </v-col>
        <v-col
          cols="6"
        >
          <v-btn
            tile
            x-large
            block
            depressed
            class="checkout-button green-color white--text mb-0"
            to="/checkout/"
            :disabled="numberOfItems < 1"
            @click="$modal.hide('cart')"
          >
            CHECKOUT
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </modal>
</template>

<script>
  import { mapGetters } from 'vuex'
  import LineItems from '~/components/LineItems.vue'

  export default {
    name: 'CheckoutModal',
    components: {
      LineItems,
    },
    props: {
      width: {
        type: [Number, String],
        default: 1500,
      },
    },
    computed: {
      ...mapGetters({
        enrolledInRiskFree: 'shopping/enrolledInRiskFree',
        lineItems: 'cart/lineItems',
        numberOfItems: 'cart/numberOfItems',
        total: 'cart/total',
        totalShipping: 'cart/totalShipping',
      }),
    },
  }
</script>

<style scoped lang="scss">
  .checkout-modal {
    &__header {
      border-top-left-radius: $global-radius;
      border-top-right-radius: $global-radius;
    }
    &__body {
      &--desktop {
        height: calc((var(--vh, 1vh) * 70) - 68px) !important;
        max-height: 800px;
      }
      &--mobile {
        height: calc((var(--vh, 1vh) * 100) - 58px) !important;
      }
    }
  }
  .checkout-modal-desktop {
    ::v-deep .vm--modal {
      height: 70% !important;
      max-height: 868px;
    }
  }
  .line-items, .cart-message {
    flex-grow: 100;
    margin-bottom: $global-margin !important;
  }
  .line-items-header {
    &__price {
      min-width: 100px !important;
    }
    &__qty {
      max-width: 130px;
      padding-right: 30px;
    }
    &__line-total {
      min-width: 100px !important;
    }
  }
  // Sets visibility of scroll bar for cart line items to always be visible, webkit browsers only since they uniquely auto hide the scroll bar
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  hr {
    color: $light-gray !important;
  }
  /* Needed for link prop on button which overrides style */
  .checkout-button:hover {
    color: white !important;
  }
  .sustainability-text {
    font-size: 12px;
    line-height: 1rem !important;
    margin-top: 2px;
  }
</style>
